import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Col, Row } from 'antd'
import {
  Contact,
  Faq,
  Menu,
  OrderNewTote,
} from '../../components'

const StoreManagement = () => {
  return (
    <Row>
      <Col lg={{ offset: 4, span: 16 }} xs={{ span: 24 }}>
        <Routes>
          <Route element={<Faq />} path="/faq" />
          <Route element={<Faq />} path="/faq/:faqIndex" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<OrderNewTote />} path="/order" />
          <Route element={<Menu />} path="*" />
        </Routes>
      </Col>
    </Row>
  )
}

export default StoreManagement
