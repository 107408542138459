const defaultMenu = [
  {
    titleKey: 'menuFaq',
    url: '/faq',
  },
  {
    titleKey: 'menuContact',
    url: '/contact',
  },
]

const frMenu = [
  {
    titleKey: 'menuFaq',
    url: '/faq',
  },
  {
    titleKey: 'menuOrderTote',
    url: '/order',
  },
]

const menus = {
  default: defaultMenu,
  fr: frMenu,
}

export default menus
