import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { Spin, Typography } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { BackButton, HelpButton } from '../../elements'

import AnswerSection from './sections/Answer'
import Questions from './sections/Questions'
import api from 'corporate/api'

const { Title } = Typography

const Faq = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { faqIndex } = useParams()

  const ref = useRef(null)

  const [faqCategories, setFaqCategories] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [errorMessage, setErrorMessage] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [fixedPosition, setFixedPosition] = useState(null)

  useEffect(() => {
    api.get('/api/v2/corporate_faqs')
      .then(({ data }) => {
        setFaqCategories(data.data)

        if (faqIndex) {
          const categoryIndex = parseInt(faqIndex.split('-')[0], 10)
          const questionIndex = parseInt(faqIndex.split('-')[1], 10)

          setSelectedQuestion(data[categoryIndex].faqs[questionIndex])
        }
      })
      .catch((error) => {
        setErrorMessage(error)
        console.log(errorMessage)
      })
      .finally((error) => {
        setIsLoading(false)
      })
  }, [])

  const showAnswer = (selectedFaq, index) => {
    setSelectedQuestion(selectedFaq)
    setFixedPosition(scrollPosition)
    navigate(`/faq/${index}`)

    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleDebounce = debounce(() => {
    window.scrollTo(0, fixedPosition)
  }, 10)

  const handleBackClick = () => {
    navigate('/faq')
    setSelectedQuestion(null)
    handleDebounce()
  }

  return (
    <Spin size="large" spinning={isLoading} tip={t('loading')}>
      <div ref={ref}>
        {selectedQuestion ? (
          <AnswerSection onClick={handleBackClick} selectedQuestion={selectedQuestion} />
        ) :
          (
            <div className="faq-card">
              <Title className="align-item-center" level={2}>
                <Trans>howWeCanHelp</Trans>
              </Title>

              <Questions faqCategories={faqCategories} onClick={showAnswer} />

              <div className="btn-wrapper">
                <HelpButton />

                <BackButton />
              </div>
            </div>
          )}
      </div>
    </Spin>
  )
}

export default Faq
