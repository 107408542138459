import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Typography } from 'antd'

const { Title } = Typography

const Questions = ({ onClick, faqCategories }) => (
  React.Children.toArray(faqCategories.map((category, catIndex) =>
    <>
      {!isEmpty(category.faqs) && (
        <Title className="faq-title" level={3}>
          {category.title}
        </Title>
      )}

      <ul className="faq-card-question-list">
        {React.Children.toArray(category.faqs?.map((faq, index) =>
          <li
            className="list-item d-flex justify-content-between"
            onClick={() => onClick(faq, `${catIndex}-${index}`)}
            role="button"
          >
            <p className="py-0">{faq.question}</p>
            <i className="arrow right"></i>
          </li>
        ))}
      </ul>
    </>
  ))
)

Questions.propTypes = {
  faqCategories: PropTypes.array,
  onClick: PropTypes.func,
}

export default Questions
