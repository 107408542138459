import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { Trans } from 'react-i18next'

const BackButton = ({ to = '/' }) => (
  <Link to={to}>
    <Button className="w-100 mt-10" shape="round" size="large" type="default">
      <Trans>back</Trans>
    </Button>
  </Link>
)

BackButton.propTypes = {
  to: PropTypes.string,
}

export default BackButton
