import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { Trans } from 'react-i18next'

const HelpButton = ({ to = '/contact' }) => (
  <Link
    className="email-btn text-decoration-none font-weight-normal p18"
    to={to}
  >
    <Button className="menu-element w-100" shape="round" size="large" type="primary">
      <Trans>need_help</Trans>
    </Button>
  </Link>
)

HelpButton.propTypes = {
  to: PropTypes.string,
}

export default HelpButton
