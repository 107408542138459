import enUS from './en-US'
import frFR from './fr-FR'
import jaJP from './ja-JP'

const translations = {
  'en-US': enUS,
  'fr-FR': frFR,
  'ja-JP': jaJP,
}

export default translations
