/*eslint no-template-curly-in-string: "off"*/
/*eslint sort-keys: "off"*/

const translates = {
  comment: 'Comment',
  enterValidPsw: 'Enter a valid password',
  helpUsKeepThisAccountSafe: 'Help us keep this account safe',
  login: 'Login',
  password: 'Password',
  menuFaq: 'FAQ',
  menuOrderTote: 'Order new tote',
  body: 'Body',
  emptySubjectOrBody: 'Please provide the subject and message',
  messageSent: 'Message sent',
  subject: 'Subject',
  sendEmail: 'Send',
  errorMsgTitle: 'Error!',
  missingUuid: 'Missing User info',
  howWeCanHelp: 'How can we help?',
  need_help: 'Still need help?',
  loading: 'Loading',
  loginFailed: 'Login Failed',
  loginFailedMessage: 'Please try again',
  back: 'Back',
  backToStoreManagement: 'Back to store management',
  succesMsgTitle: 'Success!',
  success: 'Success!',
  receivedInfoModification: 'We have received your information modification request and will be in touch',
  receivedToteOrder: 'We have received your tote order request and will be in touch.',
  emailAddress: 'Contact email address',
  numberOfTotes: 'Number of totes',
  modificationRequestError: 'Request error',
  myInformationIsIncorrect: 'My information is incorrect',
  orderRequestError: 'Order request failed',
  orderRequestSent: 'Order request sent',
  orderTotes: 'Order totes',
  phoneNumber: 'Contact phone number',
  phoneNumberUpdateError: 'Update Error',
  phoneNumberUpdateSuccess: 'Update Success',
  pleaseConfirm: 'Please, confirm your order details',
  sendUsModification: 'Send us a modification request',
  storeAddress: 'Store address',
  menuContact: 'Contact',
  clickToEdit: 'Click to edit',
}

export default translates

