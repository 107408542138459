import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { HelpButton } from '../../../elements'
import { Trans } from 'react-i18next'

const AnswerSection = ({ onClick, selectedQuestion }) => (
  <div className="visible-answer faq-card-answer">
    <h2 className="pt-0">
      <Trans>{selectedQuestion.question}</Trans>
    </h2>
    <p>{selectedQuestion.answer}</p>

    <div className="row block text-center">
      <div className="col-md btn-wrapper">
        <p className="text-center flex">
          <HelpButton to={`/contact?subject=${selectedQuestion.question}`} />
        </p>
        <Button
          className="w-100 mt-10"
          onClick={onClick}
          shape="round"
          size="large"
          type="default"
        >
          <Trans>back</Trans>
        </Button>
      </div>
    </div>
  </div>
)

AnswerSection.propTypes = {
  onClick: PropTypes.func,
  selectedQuestion: PropTypes.object,
}

export default AnswerSection
