import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from './translations'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: ['en-US', 'fr-FR', 'ja-JP'],
    interpolation: {
      escapeValue: false,
    },
    lng:  'en-US',
    resources: {
      'en-US': {
        translation: {
          ...translations['en-US'],
        },
      },
      'fr-FR': {
        translation: {
          ...translations['fr-FR'],
        },
      },
      'ja-JP': {
        translation: {
          ...translations['ja-JP'],
        },
      },
    },
  })

export default i18n
