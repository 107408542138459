import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { Trans } from 'react-i18next'
import menus from '../../constants/CountryMenus'

import { user } from 'corporate/App'

const getUserMenus = (countryCode) => (
  menus[countryCode]
    ? menus[countryCode]
    : menus.default
)

const Menu = () => (
  <div className="menu-list">
    {React.Children.toArray(
      getUserMenus(user.details.value?.country_code).map(({ titleKey, url }) => (
        <Link to={url}>
          <Button className="menu-element w-100" shape="round" size="large" type="primary">
            <Trans>{titleKey}</Trans>
          </Button>
        </Link>
      ))
    )}
  </div>
)

export default Menu
