import React from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { signal } from '@preact/signals-react'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { isEmpty } from 'lodash'
import {
  LoginPage,
  StoreManagementPage,
} from './pages'
import { refetchUser } from './pages/login/Login'

export const user = {
  details: signal(null),
  isLoading: signal(false),
  refreshToken: signal(null),
  token: signal(null),
}

const CorporateApp = () => {
  const [searchParams] = useSearchParams()
  const { i18n } = useTranslation()
  const [cookies, , removeCookie] = useCookies(['corporate_token', 'corporate_refreshToken'])

  const countyCodes = {
    fr: 'fr-FR',
    jp: 'ja-JP',
    us: 'en-US',
  }

  const countryKey = searchParams.get('country')
  const uuid = searchParams.get('uuid')

  if (!user.details.value && cookies.corporate_token) {
    const storedUuid = localStorage.getItem('uuid')

    if (!uuid || storedUuid === uuid)
      refetchUser(cookies.corporate_token, removeCookie)
  }

  if (user.isLoading.value)
    return null
  if (user.details.value) {
    if (countyCodes[user.details.value.country_code] !== i18n.language)
      i18n.changeLanguage(countyCodes[user.details.value.country_code])
  } else {
    if (!isEmpty(countryKey) && countyCodes[countryKey] !== i18n.language)
      i18n.changeLanguage(countyCodes[countryKey])
  }

  return (
    <div className="container mx-auto align-item-center">
      <div className="row">
        <div className="col-12 mb-150">
          <Routes>
            <Route element={user.details.value ? <StoreManagementPage /> : <LoginPage />} path="*" />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default CorporateApp
