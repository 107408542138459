import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Form, Input, notification } from 'antd'
import { isEmpty } from 'lodash'
import { Trans } from 'react-i18next'

import api from 'corporate/api'

const Contact = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm()

  const subjectFromUrl = searchParams.get('subject')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    form.setFieldValue('subject', subjectFromUrl)
  }, [])

  const handleLogin = (e) => {
    e.preventDefault()

    const fields = form.getFieldsValue(['subject', 'body'])

    if (isEmpty(fields.subject) || isEmpty(fields.body)) {
      notification.error({
        description: <Trans>emptySubjectOrBody</Trans>,
        duration: 5,
        message: <Trans>errorMsgTitle</Trans>,
        placement: 'bottom',
      })

      return false
    }

    setIsLoading(true)

    api.post('/api/v2/corporate/help', fields)
      .then(() => {
        notification.success({
          description: <Trans>messageSent</Trans>,
          duration: 5,
          message: <Trans>succesMsgTitle</Trans>,
          placement: 'bottom',
        })

        navigate(-1)
      })
      .catch((error) => {
        setIsLoading(false)

        notification.error({
          description: <Trans>error</Trans>,
          duration: 5,
          message: <Trans>errorMsgTitle</Trans>,
          placement: 'bottom',
        })
      })
  }

  return (
    <>
      <Form
        className="contanctForm"
        form={form}
        layout="vertical"
      >
        <Form.Item
          label={<Trans>subject</Trans>}
          name="subject"
        >
          <Input disabled={!!subjectFromUrl} placeholder=" " suffix={<></>} />
        </Form.Item>

        <Form.Item
          className="textarea-form-item"
          label={<Trans>body</Trans>}
          name="body"
        >
          <Input.TextArea
            maxLength={255}
            placeholder=" "
            rows={10}
          />
        </Form.Item>

        <Button
          className="w-100"
          disabled={isLoading}
          loading={isLoading}
          onClick={handleLogin}
          shape="round"
          size="large"
          type="primary"
        >
          <Trans>sendEmail</Trans>
        </Button>
      </Form>

      <div className="btn-wrapper">
        <Button className="w-100 mt-10" onClick={() => navigate(-1)} shape="round" size="large" type="default">
          <Trans>back</Trans>
        </Button>
      </div>
    </>
  )
}

export default Contact
