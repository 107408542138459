import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import App from './App'
import './modules/i18n'

import './index.scss'

const basename = (window.location.host.split('.')[0] === 'corporate') ? '/' : '/corporate'

const Root = () => (
  <BrowserRouter basename={basename}>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <App />
    </CookiesProvider>
  </BrowserRouter>
)

export default Root
