/*eslint no-template-curly-in-string: "off"*/
/*eslint sort-keys: "off"*/

const translates = {
  comment: 'Informations supplémentaires',
  enterValidPsw: 'Entrez un mot de passe valide',
  helpUsKeepThisAccountSafe: 'Sécuriser votre compte',
  login: 'Se connecter',
  password: 'Mot de passe',
  menuFaq: 'FAQ',
  menuOrderTote: 'Commander des totes',
  body: 'Corps',
  emptySubjectOrBody: 'Veuillez indiquer le sujet et votre message',
  messageSent: 'Message envoyé ',
  subject: 'Objet',
  sendEmail: 'Envoyer',
  errorMsgTitle: 'Erreur',
  missingUuid: 'Informations sur l\'utilisateur manquantes',
  howWeCanHelp: 'Comment pouvons-nous vous aider ?',
  need_help: 'Toujours besoin d\'aide ?',
  loading: 'Chargement',
  loginFailed: 'Échec de connexion',
  loginFailedMessage: 'Veuillez réessayer',
  back: 'Retour ',
  backToStoreManagement: 'Retour à la rubrique Gestion magasins',
  succesMsgTitle: 'Commande envoyée !',
  success: 'Demande envoyée !',
  receivedInfoModification: 'Nous avons reçu votre demande de modification et nous vous contacterons.',
  receivedToteOrder: 'Votre commande de Tote a bien été reçue, vous recevrez un nouveau lot dans les prochains jours !',
  emailAddress: 'Adresse e-mail ',
  numberOfTotes: 'Nombre de totes',
  modificationRequestError: 'Erreur dans la demande',
  myInformationIsIncorrect: 'Mes informations sont incorrectes',
  orderRequestError: 'Échec de la commande',
  orderRequestSent: 'Commande envoyée',
  orderTotes: 'Commander des totes',
  phoneNumber: 'Numéro de téléphone ',
  phoneNumberUpdateError: 'Erreur de mise à jour',
  phoneNumberUpdateSuccess: 'Mise à jour réussie',
  pleaseConfirm: 'Veuillez confirmer les détails de votre commande',
  sendUsModification: 'Envoyez-nous une demande de modification',
  storeAddress: 'Adresse du magasin',
  menuContact: 'Contact',
  clickToEdit: 'Cliquer pour modifier',
}

export default translates

