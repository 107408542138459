import React, { useRef, useState } from 'react'
import Image from 'react-bootstrap/Image'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Button, Col, Form, Input, notification, Row, Typography } from 'antd'
import { useCookies } from 'react-cookie'
import { isEmpty } from 'lodash'
import { Trans } from 'react-i18next'

import { loopKey } from 'corporate/images'

import api from 'corporate/api'
import { user } from 'corporate/App'

const { Title } = Typography

export const refetchUser = (token , removeCookie) => {
  if (!token) return false

  user.isLoading.value = true
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`

  api.get('/api/v2/user')
    .then(({ data }) => {
      user.details.value = data.data

      localStorage.setItem('uuid', user.details.value.id)
    })
    .catch((error) => {
      removeCookie('token')
      removeCookie('refreshToken')

      user.token.value = null
      user.refreshToken.value = null
      user.details.value = null
    })
    .finally((error) => {
      user.isLoading.value = false
    })
}

export const Login = () => {
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(false)
  const passwordRef = useRef('')
  const uuid = searchParams.get('uuid')

  const [, setCookie, removeCookie] = useCookies()

  const handleLogin = (e) => {
    e.preventDefault()

    const password = passwordRef.current.input.value

    if (!isEmpty(uuid) && !isEmpty(password)) {
      setIsLoading(true)

      api
        .post('/api/v2/oauth/token', {
          client_id: 'corporate_app',
          grant_type: 'password',
          password,
          uuid,
        })
        .then(({ data }) => {
          setCookie('corporate_token', data.access_token, { maxAge: data.expires_in })
          setCookie('corporate_refreshToken', data.refresh_token, { maxAge: data.expires_in })

          user.token.value = data.access_token
          user.refreshToken.value = data.refresh_token

          refetchUser(data.access_token, removeCookie)
        })
        .catch((error) => {
          notification.error({
            description: <Trans>loginFailed</Trans>,
            message: <Trans>loginFailedMessage</Trans>,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  if (!uuid && localStorage.getItem('uuid'))
    return <Navigate replace={true} to={`/?uuid=${localStorage.getItem('uuid')}`} />

  if (!uuid)
    return (
      <Title className="align-item-center">
        <Trans>missingUuid</Trans>
      </Title>
    )

  return (
    <Row>
      <Col lg={{ offset: 8, span: 8 }} xs={{ offset: 2, span: 20 }}>
        <Image src={loopKey} style={{ marginTop: '10px' }} />

        <Title as="h3" className="align-item-center blue">
          <Trans>helpUsKeepThisAccountSafe</Trans>
        </Title>

        <Form className="loginForm" form={form} layout="vertical">
          <Form.Item
            label={<Trans>password</Trans>}
            name="password"
          >
            <Input.Password onPressEnter={handleLogin} placeholder=" " ref={passwordRef} value={passwordRef} />
          </Form.Item>

          <Button
            className="w-100"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleLogin}
            shape="round"
            size="large"
            type="primary"
          >
            <Trans>login</Trans>
          </Button>

        </Form>
      </Col>
    </Row>
  )
}

export default Login
